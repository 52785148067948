class GoogleAnalytics {
  constructor(services) {
    this.config = window.app_env?.ga_config;
    this.services = services;
    this.enabled = false;
    this.buffer = new Set();
    this.init();
  }

  init() {
    if (!this.config) return
    if (["payment", "pof", "checkout"].includes(window.app_env?.page_type)) return
    if (window.app_env?.page_type?.includes("webview")) return

    this.enabled = true;
    this.loaded().then(() => {
      this.buffer?.forEach(this.sendEvent.bind(this));
      this.buffer = new Set();
      window.ga?.("create", this.config.ua_property_id, "auto")
    }).catch(() => {})
  }

  loaded() {
    return new Promise((resolve, reject) => {
      if (window.gtag) {
        resolve()
      } else {
        let limit = 30;
        let ticker = setInterval(() => {
          if (window.gtag) {
            clearInterval(ticker);
            resolve()
          } else if (limit == 0) {
            clearInterval(ticker);
            reject()
          }
          limit--
        }, 1000);
      }
    })
  }

  track(content) {
    if (!content || !this.enabled) return
    !this.sendEvent(content) && this.buffer.add(content);
  }

  sendEvent(content) {
    if (!window.gtag) return false
    window.gtag('event', content.name, {
      'send_to': this.config.measurement_id,
      'ec': content.name,
      'ea': content.category,
      'el': content.detail || "N/A",
    });
    if (content.extra) {
      window.ga?.('send', 'event', content.name, content.category, content.detail, content.extra);
    } else {
      window.ga?.('send', 'event', content.name, content.category, content.detail);
    }
    return true
  }
}

export default GoogleAnalytics