import BaseField from "./BaseField";

class PromoCodeField extends BaseField {
    constructor(el, form) {
        super(el, form);

        this.optional_fields = ["_cc_num", "_cc_name", "_cc_exp_month", "_cc_exp_year", "_cvv2",
            "country", "zip_code", "street_address_1", "street_address_2", "city", "state"];
        this.non_default_payment_methods = ["paypal", "applepay", "ecp", "pof"];
        this.default_offers = window.app_env.default_offers;
        this.promo_config = window.app_env.promo_config;
        this.listener_binds = null;
        this.abortController = null;
        this.trial_submit_text = "Start your free trial";
        this.non_trial_submit_text = "Begin your membership";
        this.initPromoCode();
        this.handleEnterSubmit();
    }

    initPromoCode() {
        this.form.el.querySelector("#apply-promo-code").addEventListener("click", this.apply.bind(this));
        this.form.el.querySelector("#edit-promo-code").addEventListener("click", this.edit.bind(this));
        if (this.promo_config) {
            setTimeout(() => {
                this.prepare_promo_offers_data(this.promo_config);
                this.update_offers(this.promo_config);
                this.update_payment_info();
            }, 0);
        }
    }

    handleEnterSubmit() {
        this.el.addEventListener('keydown', (e) => {
            if(e.key === 'Enter') {
                e.preventDefault();
                this.apply();
            }
        });
    }

    async apply() {
        let promo_code = this.field.querySelector("input[name=promo-code]").value;
        this.abortController?.abort();
        this.abortController = new AbortController();
        if (promo_code.length > 0) {
            try {
                const request_data = new FormData();
                request_data.append("promo_code", promo_code.toLowerCase());
                request_data.append("email", document.querySelector('input[name="email"]').value);
                const request_config = {
                    method: "POST",
                    headers: {"Accept": "application/json"},
                    body: request_data,
                    signal: this.abortController.signal
                };
                const apply_promo_code_response = await fetch(
                    "/join/apply-promo-code", request_config
                );
                const response = await apply_promo_code_response.json();
                if (response.status === "success") {
                    this.set_promo_config(response.data);
                    this.prepare_promo_offers_data(this.promo_config);
                    this.update_offers(this.promo_config);
                    this.update_payment_info();
                    this.hide_promo_code_field();
                    this.hide_apply_promo_code_button();
                    this.show_success_message(response.data.message);
                    this.show_edit_promo_code_link();
                } else {
                    this.show_error_message(
                        response.data.has_displayable_message ? response.data.message : null
                    );
                }
            } catch (e) {
                this.show_error_message();
            }
        }
    }

    edit(event) {
        event.preventDefault();

        this.reset_payment_info();
        this.set_promo_config(null);
        this.clear_active_promo_code();
        this.update_offers(this.default_offers);
        this.hide_edit_promo_code_link();
        this.hide_promo_code_message_field();
        this.show_promo_code_field();
        this.show_apply_promo_code_button();
    }

    clear_active_promo_code() {
        MagicCookie.setCookieValue("active_promo_code", "");
    }

    hide_promo_code_field() {
        this.field.querySelector("input[name=promo-code]").style.display = "none";
    }

    show_promo_code_field() {
        this.field.querySelector("input[name=promo-code]").style.display = "";
    }

    hide_apply_promo_code_button() {
        this.form.el.querySelector("#apply-promo-code").style.display = "none";
    }

    show_apply_promo_code_button() {
        this.form.el.querySelector("#apply-promo-code").style.display = "";
    }

    hide_edit_promo_code_link() {
        this.form.el.querySelector("#edit-promo-code").style.display = "none";
    }

    show_edit_promo_code_link() {
        this.form.el.querySelector("#edit-promo-code").style.display = "";
    }

    hide_promo_code_message_field() {
        this.field.querySelector(".field__message").style.display = "none";
    }

    show_success_message(message) {
        let message_field = this.field.querySelector(".field__message");
        message_field.innerHTML = message;
        message_field.style.display = "";
        this.removeError("validity");
    }

    show_error_message(custom_error_message) {
        let error_message = custom_error_message || "We're sorry, this promo code cannot be applied at this time";
        this.addError("validity", error_message, error_message);
    }

    prepare_promo_offers_data(offers_data) {
        let choices = offers_data.price_choices, choice, default_choice,
            default_choices = this.default_offers.price_choices;
        for (let choice_index = 0; choice_index < 3; choice_index++) {
            choice = choices[choice_index];
            if (choice) {
                default_choice = default_choices[choice_index];
                if (!choice.copy) {
                    choice.copy = "save X%";
                }
                choice.copy = this.format_offer_copy(
                    choice.copy, choice.term, choice.price, default_choice.price
                );
                choice.more_copy = this.format_offer_copy(
                    choice.more_copy, choice.term, choice.price, default_choice.price
                );
                if (!offers_data.selected_tracking_id) {
                    offers_data.selected_tracking_id = choice.tracking_id;
                }
            }
        }
    }

    format_offer_copy(offer_copy, offer_term, offer_price, default_price) {
        let amount_per_month_text = "$X.XX/month",
            amount_per_day_text = "$X.XX/day",
            was_amount_text = "was $X.XX",
            percentage_amount_text = "X%";

        if (offer_copy.includes(amount_per_month_text) && [365, 730].includes(offer_term)) {
            let total_months = Math.floor(offer_term / 30);
            let amount_per_month = (offer_price / total_months).toFixed(2);
            offer_copy = offer_copy.replace(amount_per_month_text, `&#36;${amount_per_month}/month`);
        } else if (offer_copy.includes(amount_per_month_text)) {
            // amount per month only makes sense
            // for 1 or 2 year offers, so let's
            // replace it with an amount per day
            offer_copy = offer_copy.replace(amount_per_month_text, amount_per_day_text);
        }

        if (offer_copy.includes(amount_per_day_text)) {
            let amount_per_day = (offer_price / offer_term).toFixed(2);
            offer_copy = offer_copy.replace(amount_per_day_text, `&#36;${amount_per_day}/day`);
        }
        if (offer_copy.includes(was_amount_text)) {
            offer_copy = offer_copy.replace(was_amount_text, `was &#36;${default_price}`);
        }
        if (offer_copy.includes(percentage_amount_text)) {
            let percentage_amount = Math.round(((default_price - offer_price) / default_price) * 100);
            offer_copy = offer_copy.replace(percentage_amount_text, `${percentage_amount}%`);
        }
        return offer_copy;
    }

    update_offers(offers_data) {
        this.listener_binds = this.listener_binds || {
            handle_promo_offer_selection: this.handle_promo_offer_selection.bind(this),
            handle_default_offer_selection: this.handle_default_offer_selection.bind(this)
        };
        let choice, choice_element, choice_radio_element, choice_copy_element;
        for (let choice_index = 0; choice_index < offers_data.price_choices.length; choice_index++) {
            choice = offers_data.price_choices[choice_index] || this.default_offers.price_choices[choice_index];
            choice_element = this.form.el.querySelector(
                `fieldset[name="pricechoice-fieldset"] ul li:nth-of-type(${choice_index + 1})`
            );
            choice_element.id = `li-${choice.tracking_id}`;
            choice_element.style.display = "";
            choice_radio_element = choice_element.querySelector("input[type=radio]");
            choice_radio_element.removeEventListener("click", this.listener_binds.handle_promo_offer_selection);
            choice_radio_element.removeEventListener("click", this.listener_binds.handle_default_offer_selection);
            choice_radio_element.id = `radio-${choice.tracking_id}`;
            choice_radio_element.value = choice.tracking_id;
            choice_radio_element.checked = choice.tracking_id === offers_data.selected_tracking_id ? "checked" : "";
            choice_radio_element.setAttribute("data-choice-index", choice_index);

            if (this.promo_config && offers_data.price_choices[choice_index]) {
                choice_radio_element.addEventListener("click", this.listener_binds.handle_promo_offer_selection);
            } else if (this.promo_config) {
                choice_radio_element.addEventListener("click", this.listener_binds.handle_default_offer_selection);
            }
            choice_element.querySelector(".membership-option__duration").innerHTML =
                choice.duration === "1 month" ? "Monthly" : choice.duration;
            choice_copy_element = choice_element.querySelector(".membership-option__off");
            if (choice.term > 30) {
                choice_copy_element.innerHTML = choice.copy;
            } else if (this.promo_config && offers_data.price_choices[choice_index]) {
                choice_copy_element.innerHTML = choice.copy;
                choice_copy_element.style.display = "";
            } else {
                choice_copy_element.innerHTML = "";
                choice_copy_element.style.display = "none";
            }
            choice_element.querySelector(".membership-option__price").innerHTML = `&#36;${choice.price.toFixed(2)}`;
            choice_element.querySelector(".membership-option__desc").innerHTML = choice.more_copy;
            if (choice.tracking_id === offers_data.selected_tracking_id) {
                if (choice.is_trial) {
                    this.show_trial_disclaimer(choice.trial_expiration_date);
                    this.update_submit_buttons(this.trial_submit_text);
                } else {
                    this.hide_trial_disclaimer();
                    this.update_submit_buttons(this.non_trial_submit_text);
                }
            }
        }
        let warning_message_element = this.form.el.querySelector("#promo-offer-warning-message");
        if (warning_message_element) {
            warning_message_element.remove();
        }
    }

    handle_promo_offer_selection(event) {
        this.toggle_trial_disclaimer_and_submit_text(event);
        this.hide_warning_message();
        this.update_payment_info();
    }

    handle_default_offer_selection(event) {
        this.toggle_trial_disclaimer_and_submit_text(event);
        this.show_warning_message();
        this.reset_payment_info();
    }

    toggle_trial_disclaimer_and_submit_text(event) {
        let target_radio_element = event.currentTarget;
        let choice_index = parseInt(
            target_radio_element.getAttribute("data-choice-index")
        );
        let choice = this.promo_config.price_choices[choice_index] || this.default_offers.price_choices[choice_index];
        if (choice.is_trial) {
            this.show_trial_disclaimer(choice.trial_expiration_date);
            this.update_submit_buttons(this.trial_submit_text);
        } else {
            this.hide_trial_disclaimer();
            this.update_submit_buttons(this.non_trial_submit_text);
        }
    }

    show_trial_disclaimer(trial_expiration_date) {
        let trial_disclaimer_element = this.form.el.querySelector("#trial-disclaimer");
        let trial_expiration_date_element = trial_disclaimer_element.querySelector("#trial-expiration-date");
        trial_expiration_date_element.innerHTML = trial_expiration_date;
        trial_disclaimer_element.style.display = "";
    }

    hide_trial_disclaimer() {
        let trial_disclaimer_element = this.form.el.querySelector("#trial-disclaimer");
        trial_disclaimer_element.style.display = "none";
    }

    update_submit_buttons(button_text) {
        let query_selector = ["credit-card", "ecp", "pof"].map(
            (submit_name) => {
                return `[data-name="${submit_name}-submit"] button[type="submit"] .btn__text`;
            }
        ).join(", ");
        this.form.el.querySelectorAll(query_selector).forEach(
            (button_text_element) => {button_text_element.innerHTML = button_text;}
        );
    }

    show_warning_message() {
        let warning_message_element = this.form.el.querySelector("#promo-offer-warning-message");
        if (warning_message_element) {
            warning_message_element.style.display = "";
        } else {
            this.insert_warning_message();
        }
    }

    hide_warning_message() {
        let warning_message_element = this.form.el.querySelector("#promo-offer-warning-message");
        if (warning_message_element) {
            warning_message_element.style.display = "none";
        }
    }

    insert_warning_message() {
        let message_element = document.createElement("div");
        message_element.classList.add("text-block", "form__info");
        message_element.setAttribute("id", "promo-offer-warning-message");
        let inner_paragraph = document.createElement("p");
        inner_paragraph.classList.add("form__info-inner");
        let promo_choices = this.promo_config.price_choices.filter((choice) => {return choice;});
        let promo_durations = promo_choices.map(
            (choice) => {return choice.duration === "1 month" ? "Monthly" : choice.duration;}
        );
        let warning_message = "The promo code only applies to the ";
        if (promo_durations.length === 1) {
            warning_message += `"${promo_durations[0]}" offer.`;
        } else {
            warning_message += `"${promo_durations[0]}" and "${promo_durations[1]}" offers.`;
        }
        inner_paragraph.appendChild(document.createTextNode(warning_message));
        message_element.appendChild(inner_paragraph);
        this.form.el.querySelector(".--promo-code").after(message_element);
    }

    update_payment_info() {
        if (this.promo_config.payment_info_display_status === "o") {
            this.non_default_payment_methods.forEach((payment_method_to_hide) => {
                let payment_method_element = this.get_payment_method_element(payment_method_to_hide);
                if (payment_method_element) {
                    payment_method_element.parentElement.style.display = "none";
                }
            });
            this.get_payment_method_element("credit").click();
            let optional_message_element = this.form.el.querySelector("#payment-info-optional-message");
            if (!optional_message_element) {
                this.insert_payment_info_optional_message();
            }
            this.optional_fields.forEach((field_key) => {
                let optional_field = this.form.fields.get(field_key);
                // show field as optional but require it behind-the-scenes in case credit card info is provided
                optional_field.field.classList.remove("--required");
                optional_field.field.classList.add("--stealth-required");
            });
            this.form.onBeforeSubmit = this.prepare_payment_info.bind(this);
        } else if (this.promo_config.payment_info_display_status === "h") {
            this.get_payment_method_element("credit").click();
            this.hide_payment_info();
            this.optional_fields.forEach((field_key) => {
                this.form.fields.get(field_key).field.classList.remove("--required");
            });
            this.form.onBeforeSubmit = this.prepare_payment_info.bind(this);
        }
    }

    reset_payment_info() {
        if (this.promo_config.payment_info_display_status === "o") {
            this.non_default_payment_methods.forEach((payment_method_to_show) => {
                let payment_method_element = this.get_payment_method_element(payment_method_to_show);
                if (payment_method_element) {
                    payment_method_element.parentElement.style.display = "";
                }
            });
            let optional_message_element = this.form.el.querySelector("#payment-info-optional-message");
            this.optional_fields.forEach((field_key) => {
                let optional_field = this.form.fields.get(field_key);
                optional_field.enabled = true;
                optional_field.field.classList.replace("--stealth-required", "--required");
            });
            if (optional_message_element) {
                optional_message_element.remove();
            }
            this.form.extraRequestParams = {};
            this.form.onBeforeSubmit = (form_instance) => {};
        } else if (this.promo_config.payment_info_display_status === "h") {
            this.show_payment_info();
            this.optional_fields.forEach((field_key) => {
                let optional_field = this.form.fields.get(field_key);
                optional_field.enabled = true;
                optional_field.field.classList.add("--required");
            });
            this.form.extraRequestParams = {};
            this.form.onBeforeSubmit = (form_instance) => {};
        }
    }

    hide_payment_info() {
        this.form.el.querySelector(`fieldset[name="paymethod-fieldset"]`).style.display = "none";
        this.form.el.querySelector(`div[data-name="payment-gateways"]`).style.display = "none";
        this.form.el.querySelector(`div[data-name="payment-billing-info"]`).style.display = "none";
    }

    show_payment_info() {
        this.form.el.querySelector(`fieldset[name="paymethod-fieldset"]`).style.display = "";
        this.form.el.querySelector(`div[data-name="payment-gateways"]`).style.display = "";
        this.form.el.querySelector(`div[data-name="payment-billing-info"]`).style.display = "";
    }

    prepare_payment_info() {
        if (this.promo_config.payment_info_display_status === "o" && this.form.fields.get("_cc_num").value.length) {
            // we want optional fields enabled for normal validation without pass-thru information
            this.optional_fields.forEach((field_key) => {
                this.form.fields.get(field_key).enabled = true;
            });
            this.form.extraRequestParams = {};
        } else {
            // we want to skip optional fields and use pass-thru information
            this.optional_fields.forEach((field_key) => {
                this.form.fields.get(field_key).enabled = false;
            });
            this.form.extraRequestParams = {
                _cc_num: "",
                _cc_name: "",
                _cc_exp_month: "",
                _cc_exp_year: "",
                _cvv2: "",
                // country is always needed, so we sneak it through
                country: this.form.fields.get("country").value,
                zip_code: "",
                street_address_1: "",
                street_address_2: "",
                city: "",
                state:"",
                rc: "000-0000-000"
            };
        }
    }

    set_promo_config(promo_config) {
        this.promo_config = promo_config;
    }

    get_payment_method_element(payment_method) {
        let selector = `fieldset[name="paymethod-fieldset"] input[value="${payment_method}"]`;
        return this.form.el.querySelector(selector);
    }

    insert_payment_info_optional_message() {
        let message_element = document.createElement("div");
        message_element.classList.add("text-block", "--lighter", "--smallest");
        message_element.setAttribute("id", "payment-info-optional-message");
        let inner_paragraph = document.createElement("p");
        let message_text = document.createTextNode(`
            Entering payment information is optional. If you
            decide to input payment details, keep in mind
            that all memberships will renew automatically.
            To cancel, simply visit the Manage Membership Page.
        `);
        inner_paragraph.appendChild(message_text);
        message_element.appendChild(inner_paragraph);
        this.form.el.querySelector(`fieldset[name="paymethod-fieldset"]`).after(message_element);
    }
}

export default PromoCodeField;
