import Preview from "./Preview";
import Utility from "../../../base/Utility";

/**
 * Represents a preview of a Creatacard product.
 * Extends the Preview class.
 */
class CreatacardPreview extends Preview {
  constructor(el, product, pers) {
    super(el, product, pers);
  }

  afterInit() {
    this.initCreatacard();
  }

  getGiftOptions() {
    const gift = this.order?.gift;
    const isGiftMissing = ( !gift || !window.app_env?.reply_token ||
                          Object.keys(gift).length === 0 ||
                          gift.name.toLowerCase() === "custom song"
                        );

    if (isGiftMissing) return {};

    return {
      giftcard: {
        image: gift.image_url,
        title: gift.title,
        price: gift.price,
        redeemUrl: gift.claim_link,
        redeemText: "Redeem Gift",
      }
    };
  }

  async getOptions(pers = {}) {
    const options = {
      productMode: "pickup",
      isPickup: true,
      host: this.config.card3d_options_host,
      ...this.getGiftOptions(),
      ...pers,
    }

    if (options.video && options.video.playbackUrl) {
      options.video.enabled = this.config.flags.includes("video");
    }

    if (options.envelope && this.order.recipients[0]?.name) {
      options.envelope.toBin.text = `To: ${this.order.recipients[0]?.name}`;
      options.envelope.fromBin.text = `From: ${this.order.sender?.name}`;
    }

    if (options?.features?.music?.tracks[0]?.type === "custom" && app_env.custom_song_full_song) {
      options.features.music.tracks[0].exploreSongLink = this.order.gift.claim_link;
      options.features.music.tracks[0].url = app_env.custom_song_full_song;
    }

    if (this.order?.gift?.name?.toLowerCase() === "cash gift") {
      let cashImage = null;
      try {
        cashImage = await Utility.getImageFromUrl(options.giftcard.image);
      } catch (error) {
        console.log("Unable to open the gift image");
      }
      if (cashImage) {
        options.giftcard.image = this.adaptCashImage(cashImage);
      }
    }

    return options
  }

  adaptCashImage(cashImage) {
    const card = document.createElement("canvas");
    const ctx = card.getContext("2d");
    card.width = cashImage.width;
    card.height = cashImage.height;
    ctx.drawImage(cashImage, 0, 0, cashImage.width, cashImage.height);
    ctx.textAlign = "right";
    ctx.textBaseline = "bottom";
    let fontSize = cashImage.height / 4;
    ctx.font = `bold ${fontSize}px "Gordita"`;
    ctx.fillStyle = "#fff";
    ctx.fillText(
      `$${this.order.gift.price}`,
      card.width - 20,
      card.height - 20,
      card.width
    );
    return card.toDataURL();
  }

  async initCreatacard() {
    if (!this.config) return;
    let personalizations = await this.loadPersonalizations();
    if (!personalizations) return console.log("No personalizations");
    if (personalizations.envelope) {
      document.querySelector(".product-pickup")?.classList.remove("--no-envelope");
    }
    const loader = new agi.CardLoader(this.container);
    let options = await this.getOptions(personalizations);
    if (!options) return console.log("No options");
    loader.load(options);
    loader.addEventListener('cardReady', () => {
      this.el.querySelector('#cac-card3d-loader')?.classList.add("hidden")
      this.el.querySelector('#giftcard-redeem')?.classList.add("btn", "--small", "--narrow");
    })
  }

}

export default CreatacardPreview;