import Client from "../base/Client";
import Render from "../base/Render";

class Wizard {
  constructor(el) {
    this.el = el;
    this.panels = new Map();
    this.form = null;
    this.activePanel = null;
    this.speed = el.hasAttribute('data-speed') ? Number(el.hasAttribute('data-speed')) : 400;
    this.flows = new Map();
    this.activeFlow = null;
    this.originalDocumentTitle = document.title;
    // Hooks
    this.onPanelChange = () => { };
    this.onFlowChange = () => { };
    // Init sequence
    this.init();
  }

  init() {
    if (this.el.id == 'join-flow') {
      this.flows.set('welcome', ['welcome', 'login']);
      this.flows.set('register-email', ['welcome', 'register-email']);
      this.flows.set('register-social', ['welcome', 'register-social']);
      this.flows.set('login', ['welcome', 'login']);
      this.flows.set('membership-payment', ['membership-payment', 'success']);
    }

    const els = this.el.querySelectorAll('.wizard-panel');
    if (!els || els.length == 0) return
    els.forEach(this.initPanel.bind(this));
    this.el.style.setProperty('--wizard-speed', this.speed / 1000 + 's');
    let hasCurrent = false;
    this.panels.forEach(panel => {
      if (panel.el.classList.contains('--active')) {
        panel.initNavigation();
        this.activePanel = panel;
        document.title = panel.getDocumentTitle();
        this.step(panel.name, false);
        hasCurrent = true;
      }
    })
    if (!hasCurrent) {
      const [firstKey] = this.panels.keys();
      this.step(firstKey, false);
    }
    this.el.classList.add("--init");
  }

  initPanel(el, counter = null) {
    if (!counter) {
      counter = this.panels.size;
    }
    let name = `${this.el.id}-panel-${counter}`;
    if (el.hasAttribute('data-name')) {
      name = `${el.getAttribute('data-name')}`;
    }
    if (this.panels.has(name)) return
    el.setAttribute('data-index', counter);
    this.panels.set(name, new Panel(el, name, counter, this));
  }

  setFlow(name) {
    if (!this.flows.has(name)) return
    if (this.activeFlow && name == 'membership-payment' && !this.activeFlow.includes('membership-payment')) {
      this.flows.set(name, this.activeFlow.concat(this.flows.get(name)))
    }
    this.activeFlow = this.flows.get(name);
    this.panels.forEach(panel => panel.disable())
    this.flows.get(name).forEach(panelName => {
      if (!this.panels.has(panelName)) return
      this.panels.get(panelName).enable();
    });
    this.onFlowChange(this);
  }

  step(name, focus = true) {
    if (this.activePanel && this.activePanel.name == name) return
    if (!this.panels.has(name)) return
    const previous = this.activePanel && this.panels.get(name).index > this.activePanel.index;
    this.activePanel?.deactivate(previous);
    this.setFlow(name);
    this.panels.get(name).activate(focus);
    this.onPanelChange(this);
    if (name == 'welcome') {
      this.panels.get('login')?.form?.clear(true, true);
    }
  }

  getPrevious(index) {
    if (index == 0) return false;
    let result = false;
    this.panels.forEach(panel => {
      if (panel.index == index - 1) {
        if (panel.enabled) {
          result = panel;
        } else {
          result = this.getPrevious(index - 1);
        }
      }
    });
    return result;
  }

  getNext(index) {
    if (index == this.panels.size - 1) return false;
    let result = false;
    this.panels.forEach(panel => {
      if (panel.index == index + 1) {
        if (panel.enabled) {
          result = panel;
        } else {
          result = this.getNext(index + 1);
        }
      }
    });
    return result;
  }

  setDimensions(el = null) {
    this.el.style.setProperty('--wizard-width', el ? el.getBoundingClientRect().width + 'px' : '');
    this.el.style.setProperty('--wizard-height', el ? el.getBoundingClientRect().height + 'px' : '');
  }

  previous() {
    if (this.el.classList.contains('--transitioning')) return
    const previous = this.getPrevious(this.activePanel.index);
    if (!previous) return;
    this.step(previous.name);
  }

  next() {
    if (this.el.classList.contains('--transitioning')) return
    const next = this.getNext(this.activePanel.index);
    if (!next) return;
    this.step(next.name);
  }
}

class Panel {
  constructor(el, name, counter, wizard) {
    this.el = el;
    this.index = counter;
    this.form = null;
    this.name = name;
    this.label = this.el.getAttribute('aria-label') || this.name;
    this.wizard = wizard;
    this.active = false;
    this.description = '';
    this.enabled = true;
    this.focus = false;
    this.listenersController = new AbortController();
    this.init();
  }

  init() {
    this.description = this.el.querySelector('.wizard-panel__header p')?.textContent.replace(/[\n\r]+|[\s]{2,}/g, ' ').trim() || '';
    if (this.description != '') {
      this.el.querySelector('.wizard-panel__header p').id = `${this.name}-description`;
      this.el.setAttribute('aria-describedby', `${this.name}-description`);
    }
    const form = this.el.querySelector('.form');
    if (form && window.app_forms?.collection.has(form.id)) {
      this.form = window.app_forms?.collection.get(form.id);
      this.form.onResponse = this.responseHandler.bind(this);
    }
  }

  responseHandler(form) {
    const key = form.lastRequest?.response?.data?.key;
    if (!key) return
    if (form.lastRequest.response.status === 'success') {
      let panel = this.wizard.panels.get(key);
      let message = form.lastRequest?.response?.data?.forward_message;
      let fillData = form.lastRequest?.response?.data?.forward_fill;
      if (!panel) return
      fillData && panel.form.fill(fillData);
      if (message) {
        setTimeout(() => {
          panel.form.addError("server", message)
          panel.form.renderErrors();
        }, this.wizard.speed + 100);
      }
      this.wizard.step(key);
    }
  }

  enableNavigation() {
    this.el.querySelector(".wizard-panel__header")?.classList.remove("--no-back");
    this.el.querySelector(".wizard-panel__back-holder")?.classList.remove("hidden");
    this.el.querySelectorAll(".wizard-panel__back, .wizard-panel__next")?.forEach(el => el.classList.remove("--disabled"));
  }

  disableNavigation() {
    this.el.querySelector(".wizard-panel__header")?.classList.add("--no-back");
    this.el.querySelector(".wizard-panel__back-holder")?.classList.add("hidden");
    this.el.querySelectorAll(".wizard-panel__back, .wizard-panel__next")?.forEach(el => el.classList.add("--disabled"));
  }

  initNavigation() {
    this.el.querySelectorAll('.wizard-panel__next').forEach(this.initNextBtn.bind(this));
    this.el.querySelectorAll('.wizard-panel__back').forEach(this.initPrevBtn.bind(this));
  }

  initNextBtn(el) {
    const next = this.wizard.getNext(this.index);
    el.addEventListener('click', this.wizard.next.bind(this.wizard), { signal: this.listenersController.signal });
    if (next) {
      el.setAttribute('aria-label', `continue to ${next.label} panel`);
      el.parentElement.classList.remove('--hide');
      el.classList.remove('--disabled');
      el.setAttribute('aria-disabled', false);
      if (el.hasAttribute('disabled')) el.removeAttribute('disabled');
    } else {
      el.parentElement.classList.add('--hide');
      el.classList.add('--disabled');
      el.setAttribute('disabled', true);
      el.setAttribute('aria-disabled', true);
    }
  }

  initPrevBtn(el) {
    const previous = this.wizard.getPrevious(this.index);
    el.addEventListener('click', this.wizard.previous.bind(this.wizard), { signal: this.listenersController.signal });
    if (previous) {
      previous.el.classList.add('--previous')
      el.setAttribute('aria-label', `go back to ${previous.label} panel`);
      el.parentElement.classList.remove('--hide');
      el.classList.remove('--disabled');
      el.setAttribute('aria-disabled', false);
      if (el.hasAttribute('disabled')) el.removeAttribute('disabled');
    } else {
      el.parentElement.classList.add('--hide');
      el.classList.add('--disabled');
      el.setAttribute('aria-disabled', true);
      el.setAttribute('disabled', true);
    }
  }

  enable() {
    this.enabled = true;
  }

  disable() {
    this.enabled = false;
  }

  activate(focus = true) {
    this.wizard.el.classList.add('--staging');
    this.focus = focus;
    this.active = true;
    this.el.classList.add('--staging');
    this.wizard.activePanel = this;
    this.focus && this.el.setAttribute('tabindex', '-1');
    this.initNavigation();
    if (this.wizard.el.classList.contains('--transitioning')) {
      Client.dispatchEvent('panel-before-activation', { instance: this }, this.el);
      if (this.wizard.el.id == "send-flow") {
        window.app_accessibility?.speak(`${this.getLabel()}, ${this.description}`);
      } else {
        window.app_accessibility?.speak(`Switched to ${this.getLabel()} panel, ${this.description}`);
      }
      this.el.style.setProperty('--panel-width', this.el.getBoundingClientRect().width + 'px');
      this.el.classList.remove('--staging');
      this.el.classList.add('--activating');
      setTimeout(() => this.wizard.setDimensions(this.el))
      setTimeout(this.activationComplete.bind(this), this.wizard.speed)
    }
  }

  getDocumentTitle() {
    if (this.el.getAttribute('data-title')) {
      return Render.html(this.el.getAttribute('data-title'))
    } else {
      return this.wizard.originalDocumentTitle
    }
  }

  getLabel() {
    return this.el.getAttribute('aria-label');
  }

  activationComplete() {
    Client.dispatchEvent('panel-activated', { instance: this }, this.el);
    document.title = this.getDocumentTitle();
    this.wizard.setDimensions();
    this.wizard.el.classList.remove('--transitioning');
    this.el.style.setProperty('--panel-width', '');
    this.el.classList.remove('--activating');
    this.el.classList.add('--active');
    this.el.classList.remove('--previous');
    setTimeout(() => {
      if (this.el.contains(document.activeElement)) return
      this.focus && this.el.focus({
        preventScroll: true
      });
    }, 100);
  }

  deactivate(previous = false) {
    previous && this.el.classList.add('--previous');
    this.active = false;
    this.wizard.activePanel = null;
    this.wizard.el.classList.add('--staging');
    this.el.classList.add('--staging');
    this.el.style.setProperty('--panel-width', this.el.getBoundingClientRect().width + 'px');
    this.wizard.setDimensions(this.el);
    this.wizard.el.classList.add('--transitioning');
    setTimeout(() => {
      Client.dispatchEvent('panel-before-deactivation', { instance: this }, this.el);
      this.el.classList.add('--deactivating');
    })
    setTimeout(this.deactivationComplete.bind(this), this.wizard.speed);
  }

  deactivationComplete() {
    Client.dispatchEvent('panel-deactivated', { instance: this }, this.el);
    this.el.style.setProperty('--panel-width', '');
    setTimeout(() => {
      this.el.classList.remove('--active');
      this.el.classList.remove('--deactivating');
      this.el.classList.remove('--staging');
      this.wizard.el.classList.remove('--staging');
    }, 10)
  }
}

export default Wizard;